/* Options:
Date: 2025-04-03 14:06:04
Version: 5.40
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:3000

//GlobalNamespace: 
//MakePropertiesOptional: True
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
DefaultImports: LoginCredentials from './LoginCredentials'; export * from './LoginCredentials';
*/

import LoginCredentials from './LoginCredentials'; export * from './LoginCredentials';;

export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IGet
{
}

export interface IPost
{
}

export class Algorithm
{
    public id: number;
    public name: string;
    public description: string;
    public enabled: boolean;
    public order: number;
    public minSelection: number;
    public maxSelection: number;
}

export interface IHasStudyNumber
{
    studyNumber?: string;
}

export enum JobStatus
{
    Queued = 1,
    Processing = 2,
    PositiveDiagnosis = 3,
    NoDiagnosis = 4,
    Failure = 5,
}

export enum MatchStatus
{
    Queued = 1,
    Processing = 2,
    Complete = 3,
    Failure = 4,
}

export enum PatientStatus
{
    Incomplete = 1,
    Review = 2,
    Correction = 3,
    Matching = 4,
    Analysis = 5,
    Results = 6,
    Complete = 7,
    Actioned = 8,
}

export enum FileType
{
    Photo = 1,
    Consent = 2,
    Karyotype = 3,
    Genetic = 4,
}

export class File
{
    public id: number;
    public type: FileType;
    public fileName: string;
    public extension: string;
    public mimeType: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
}

export enum FaceMatchUploadStatus
{
    Accepted = 1,
    NoFace = 2,
    Duplicate = 3,
    UploadFailed = 4,
}

export class PatientPhoto
{
    public id: number;
    public patientId: number;
    public imagusFaceId: number;
    public fileId: number;
    public years: number;
    public months: number;
    public photographFocusId: number;
    public faceMatchUploadStatus: FaceMatchUploadStatus;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public temporaryFileGuid: string;
    public showForMatching: boolean;
    public inadequateQuality: boolean;
    public reference: boolean;
}

export class GeneticDocument
{
    public id: number;
    public patientId: number;
    public fileId: number;
    public fileName: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public temporaryFileGuid: string;
}

export class PatientDnaVariant
{
    public id: number;
    public patientId: number;
    public myVariantId: string;
    public variant: string;
    public zygosityId: number;
    public inheritanceId: number;
    public clinicalSignificanceId: number;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
}

export class PatientReference
{
    public id: number;
    public patientId: number;
    public researchGroupId: number;
    public referenceNumber: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
}

export class PatientRelative
{
    public id: number;
    public patientId: number;
    public relationshipId: number;
    public inFaceMatchId: number;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
}

export class PatientHpoTerm
{
    public id: number;
    public patientId: number;
    public termId: string;
    public termName: string;
    public enteredBy: number;
    public enteredDate: string;
    public deletedBy: number;
    public deletedDate: string;
}

export class PatientContact
{
    public id: number;
    public patientId: number;
    public personId: number;
    public enteredDate: string;
}

export enum AuthenticationProvider
{
    Google = 1,
    Facebook = 2,
    Twitter = 3,
    System = 4,
    Credentials = 5,
}

export class PersonAuthentication
{
    public id: number;
    public personId: number;
    public authenticationProvider: AuthenticationProvider;
    public reference: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
}

export enum Role
{
    SuperUser = 1,
    Administrator = 2,
    Expert = 3,
    Doctor = 4,
    Parent = 5,
    Patient = 6,
    Reference = 7,
    AssociatedDoctor = 8,
    Researcher = 9,
}

export class PersonRole
{
    public id: number;
    public personId: number;
    public role: Role;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
}

export class Doctor
{
    public id: number;
    public personId: number;
    public institute: string;
    public city: string;
    public state: string;
    public postcode: string;
    public countryId: number;
    public medicalAreaId: number;
    public medicalAreaOther: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
}

export class Person
{
    public id: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public emailPreviousVerified: string;
    public emailGuid: string;
    public emailVerified: boolean;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public phoneNumber: string;
    public apiKey: string;
    public authentications: PersonAuthentication[];
    public roles: PersonRole[];
    public doctorInformation: Doctor;
    public patientInformation: Patient;
    public childCount: number;
    public patientCount: number;
    public hasParent: boolean;
    public hasDoctor: boolean;
    public visibleToExperts: boolean;
    public deactivated: boolean;
}

export enum InvitationStatus
{
    Pending = 1,
    Accepted = 2,
    Rejected = 3,
    Cancelled = 4,
}

export class Invitation
{
    public id: number;
    public personId: number;
    public patientId: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public countryId: number;
    public patientFirstName: string;
    public patientLastName: string;
    public role: Role;
    public status: InvitationStatus;
    public reason: string;
    public guid: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public resentBy: number;
    public resentDate: string;
    public phoneNumber: string;
    public invitee: Person;
    public invitor: Person;
    public patient: Patient;
    public selfinvite: boolean;
    public registerNow: boolean;
}

export class Patient
{
    public id: number;
    public personId: number;
    public studyNumber: string;
    public status: PatientStatus;
    public consentParticipation: boolean;
    public consentShareImages: boolean;
    public consentContact: boolean;
    public consentRetainImages: boolean;
    public consentMatchMakerExchange: boolean;
    public consentFileId: number;
    public consentUploadDate: string;
    public consentApproved: boolean;
    public consentApprovedDate: string;
    public middleName: string;
    public genderId: number;
    public dateOfBirth: string;
    public intellectualDisabilityId: number;
    public intellectualDisabilityName: string;
    public diagnosisConfirmedId: number;
    public clinicalDiagnosisId: number;
    public diagnosisAgeYears: number;
    public diagnosisAgeMonths: number;
    public omimPhenotypeDoesNotExist: boolean;
    public omimPhenotypeName: string;
    public mimPhenotypeNumber: string;
    public omimGeneDoesNotExist: boolean;
    public omimGeneName: string;
    public mimGeneNumber: string;
    public secondDiagnosisId: number;
    public secondDiagnosisOmimPhenotypeDoesNotExist: boolean;
    public secondDiagnosisOmimPhenotypeName: string;
    public secondDiagnosisMimPhenotypeNumber: string;
    public secondDiagnosisOmimGeneDoesNotExist: boolean;
    public secondDiagnosisOmimGeneName: string;
    public secondDiagnosisMimGeneNumber: string;
    public developmentalDelayId: number;
    public psychometricTestingId: number;
    public diagnosisProvisionalId: number;
    public testingTraditionalKaryotype: boolean;
    public testingMolecularKaryotype: boolean;
    public testingFragileX: boolean;
    public testingBiochemical: boolean;
    public testingSingleGene: boolean;
    public testingMethylationStudies: boolean;
    public testingMitochondrial: boolean;
    public testingGenePanel: boolean;
    public testingExomeSequencingResultPending: boolean;
    public testingExomeSequencing: boolean;
    public testingGenomeSequencingResultPending: boolean;
    public testingFunctionalStudiesPending: boolean;
    public testingGenomeSequencing: boolean;
    public testingOther: boolean;
    public copyNumberVariantId: number;
    public decipherReferenceNumber: string;
    public karyotypeFileId: number;
    public humanPhenotypeOntology: string;
    public historyOfIntellectualDisabilityId: number;
    public inheritanceAutosomalDominant: boolean;
    public inheritanceAutosomalRecessive: boolean;
    public inheritanceXLinked: boolean;
    public inheritanceImprinting: boolean;
    public inheritanceMitochondrial: boolean;
    public inheritanceDenovo: boolean;
    public inheritanceOther: boolean;
    public visitedGeneticistId: number;
    public parentsRelatedId: number;
    public ivfConceptionId: number;
    public disabilityCauseAntenatalComplication: boolean;
    public disabilityCausePerinatalComplication: boolean;
    public disabilityCauseInfection: boolean;
    public disabilityCauseChronicDisease: boolean;
    public disabilityCauseTrauma: boolean;
    public disabilityNA: boolean;
    public studiesAustralianSolveIdPhenotypingConsortium: boolean;
    public studiesRna4rd: boolean;
    public studiesPersyt: boolean;
    public studiesAfgn: boolean;
    public studiesUdn: boolean;
    public studiesNswGold: boolean;
    public studiesNA: boolean;
    public guid: string;
    public imagusPersonId: string;
    public faceMatchDiagnosisId: number;
    public faceMatchDiagnosisNotes: string;
    public doiReference: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public consentFile: File;
    public photos: PatientPhoto[];
    public images: GeneticDocument[];
    public dnaVariants: PatientDnaVariant[];
    public references: PatientReference[];
    public relatives: PatientRelative[];
    public patientHpoTerms: PatientHpoTerm[];
    public contacts: PatientContact[];
    public person: Person;
    public parent: Person;
    public doctor: Person;
    public associatedDoctors: Person[];
    public doctorInvitation: Invitation;
    public parentInvitation: Invitation;
    public temporaryConsentFileGuid: string;
    public temporaryKaryotypeGuid: string;
    public associatedPatient: boolean;
}

export class MatchResult
{
    public id: number;
    public matchId: number;
    public patientId: number;
    public patientPhotoId: number;
    public order: number;
    public distance: number;
    public probability: number;
    public matchScore: number;
    public quality: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public patient: Patient;
}

export class Match
{
    public id: number;
    public matchBatchId: number;
    public patientPhotoId: number;
    public status: MatchStatus;
    public token: string;
    public seconds: number;
    public faceCount: number;
    public model: number;
    public peopleCount: number;
    public resultCount: number;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public results: MatchResult[];
}

export class JobMatch
{
    public id: number;
    public jobId: number;
    public matchId: number;
    public match: Match;
}

export class Job
{
    public id: number;
    public patientId: number;
    public algorithmId: number;
    public status: JobStatus;
    public emailSent: boolean;
    public score: number;
    public result: string;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public jobMatches: JobMatch[];
    public referenceCount: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export class JobReference
{
    public id: number;
    public jobId: number;
    public patientPhotoId: number;
    public score: number;
    public order: number;
    public patient: Patient;
}

export class DnaVariant
{
    public aminoAcidChange: string;
    public chromosome: string;
    public cytogenetic: string;
    public dbSNP: string;
    public geneSymbol: string;
    public hgvS_c: string;
    public name: string;
    public variationID: string;
}

export interface IHasFileId
{
    id?: number;
}

export class HpoTerm
{
    public id: string;
    public name: string;
    public definition: string;
}

export enum LookupType
{
    Country = 'Country',
    MedicalArea = 'MedicalArea',
    ClinicalSignificance = 'ClinicalSignificance',
    CopyNumberVariant = 'CopyNumberVariant',
    DevelopmentalDelay = 'DevelopmentalDelay',
    DiagnosisConfirmed = 'DiagnosisConfirmed',
    ClinicalDiagnosis = 'ClinicalDiagnosis',
    SecondDiagnosis = 'SecondDiagnosis',
    DiagnosisProvisional = 'DiagnosisProvisional',
    FaceMatchDiagnosis = 'FaceMatchDiagnosis',
    Gender = 'Gender',
    HistoryOfIntellectualDisability = 'HistoryOfIntellectualDisability',
    Inheritance = 'Inheritance',
    IntellectualDisability = 'IntellectualDisability',
    IvfConception = 'IvfConception',
    Relationship = 'Relationship',
    ResearchGroup = 'ResearchGroup',
    InFaceMatch = 'InFaceMatch',
    ParentsRelated = 'ParentsRelated',
    PhotographFocus = 'PhotographFocus',
    PsychometricTesting = 'PsychometricTesting',
    VisitedGeneticist = 'VisitedGeneticist',
    Zygosity = 'Zygosity',
}

export enum TemplateType
{
    ParentRegistered = 1,
    ParentDoctorRejectInvite = 2,
    ParentDoctorAcceptInvite = 3,
    ParentWelcomeAcceptInviteFromDoctor = 4,
    ParentDoctorSendInvite = 5,
    DoctorParentSendInvite = 6,
    DoctorWelcome = 7,
    DoctorCompletePatientForm = 8,
    DoctorParentRejectInvite = 9,
    DoctorParentAcceptInvite = 10,
    DoctorParentUpdatePatient = 11,
    DoctorExpertDataIssue = 12,
    ExpertDoctorSubmitPatientForm = 13,
    ExpertDoctorResubmitPatientForm = 14,
    ExpertDiagnosisAnalysisComplete = 15,
    ParentDoctorSubmitPatientForm = 16,
    ParentExpertDataIssue = 17,
    DoctorExpertEnterDiagnosis = 18,
    EmailVerification = 19,
    EnrolPatientLaterEmail = 20,
    PatientRegistrationMissingDoctor = 21,
    PasswordReset = 22,
    PasswordUpdate = 23,
    BulkPasswordSet = 24,
    ParentInviteFailureEmail = 25,
    ParentSelfSendInvite = 26,
    ExpertTemplateDiagnosedPatient = 27,
    ExpertTemplateUnDiagnosedPatient = 28,
    ParentSelfSendInviteAdmin = 29,
    AddAssociateDoctorToPatient = 30,
}

export class MailAddress
{
    public address: string;
    public displayName: string;
}

export class MailAttachment
{
    public fileName: string;
    public guid: string;
}

export class Mail
{
    public templateType: TemplateType;
    public subject: string;
    public body: string;
    public from: MailAddress;
    public toAddresses: MailAddress[];
    public ccAddresses: MailAddress[];
    public bccAddresses: MailAddress[];
    public attachments: MailAttachment[];
}

export class TableFilter
{
    public propertyName: string;
    public value: Object;
}

export enum TableSortOrder
{
    Ascending = 1,
    Descending = 2,
}

export class TableSort
{
    public propertyName: string;
    public sortOrder: TableSortOrder;
}

export enum RecipientType
{
    To = 1,
    Cc = 2,
    Bcc = 3,
}

export class MailLogRecipient
{
    public id: number;
    // @References(typeof(MailLog))
    public mailLogId: number;

    public recipientName: string;
    public recipientEmail: string;
    public recipientType: RecipientType;
}

export class CustomField
{
    public id: number;
    public name: string;
    public label: string;
}

export class Template
{
    public id: number;
    public name: string;
    public description: string;
    public customFields: CustomField[];
}

export class MailLog
{
    public id: number;
    // @References(typeof(Template))
    public templateId: number;

    public subject: string;
    public body: string;
    public dateSent: string;
    public fromName: string;
    public fromEmailAddress: string;
    public recipients: MailLogRecipient[];
    public template: Template;
}

export class OmimPhenotype
{
    public id: number;
    public preferredLabel: string;
    public classID: string;
    public synonyms: string[];
    public hasRelatedGenotypes: boolean;
}

export class OmimGenotype
{
    public id: number;
    public preferredLabel: string;
    public classID: string;
    public synonyms: string[];
    public genotypeSymbols: string;
}

export enum OmimSearchType
{
    OmimPhenotypeName,
    OmimPhenotypeMimNumber,
    OmimGenotypeName,
    OmimGenotypeMimNumber,
}

export interface IHasPatient
{
    patient?: Patient;
}

export enum MatchBatchStatus
{
    Queued = 1,
    Processing = 2,
    Complete = 3,
    Failure = 4,
}

export class MatchBatch
{
    public id: number;
    public patientId: number;
    public status: MatchBatchStatus;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public matches: Match[];
}

export class QueueAlgorithm
{
    public algorithmId: number;
    public photographs: number[];
}

export class Comment
{
    public id: number;
    public patientId: number;
    public body: string;
    public commenterId: number;
    public enteredBy: number;
    public enteredDate: string;
    public modifiedBy: number;
    public modifiedDate: string;
    public commenter: Person;
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1, EmitDefaultValue=false)
    public errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    public fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    public message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    public meta: { [index:string]: string; };
}

export class PersonToken
{
    public id: number;
    public personId: number;
    public token: string;
    public dateCreated: string;
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate: string;
}

export class Lookup implements ILookup
{
    public id: number;
    public value: string;
    public rank: number;
    public enabled: boolean;
}

export interface ILookup
{
    id?: number;
    value?: string;
    rank?: number;
    enabled?: boolean;
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index:string]: string; };
}

export class GetAnalysisAlgorithmsResponse
{
    public responseStatus: ResponseStatus;
    public algorithms: Algorithm[];
}

export class GetPatientJobResponse
{
    public responseStatus: ResponseStatus;
    public job: Job;
}

export class GetPatientJobsResponse
{
    public responseStatus: ResponseStatus;
    public jobs: Job[];
}

export class QueueJobResponse
{
    public responseStatus: ResponseStatus;
    public job: Job;
    public patient: Patient;
}

export class GetPatientJobReferencesResponse
{
    public responseStatus: ResponseStatus;
    public references: JobReference[];
}

export class FindDnaVariantResponse
{
    public responseStatus: ResponseStatus;
    public dnaVariants: DnaVariant[];
}

export class FindHpoTermResponse
{
    public responseStatus: ResponseStatus;
    public hpoTerms: HpoTerm[];
    public pageCount: number;
    public totalCount: number;
}

export class GetInvitationByGuidResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;
}

export class AcceptInvitationByGuidResponse
{
    public responseStatus: ResponseStatus;
}

export class RejectInvitationByGuidResponse
{
    public responseStatus: ResponseStatus;
}

export class CancelInvitationByGuidResponse
{
    public responseStatus: ResponseStatus;
}

export class ResendInvitationByGuidResponse
{
    public responseStatus: ResponseStatus;
}

export class SendParentInviteResponse
{
    public invitation: Invitation;
    public responseStatus: ResponseStatus;
}

export class GetInvitationsByParentResponse
{
    public responseStatus: ResponseStatus;
    public invitations: Invitation[];
}

export class GetInvitationsByDoctorResponse
{
    public responseStatus: ResponseStatus;
    public invitations: Invitation[];
}

export class UpdateInvitationUserByGuidResponse
{
    public responseStatus: ResponseStatus;
}

export class AcceptInvitationFromDoctorByGuidResponse
{
    public responseStatus: ResponseStatus;
}

export class GetLookupsResponse
{
    public responseStatus: ResponseStatus;
    public lookups: { [index:string]: Lookup[]; };
}

export class GetEmailsResponse
{
    public responseStatus: ResponseStatus;
    public emails: Mail[];
}

export class LoadEmailLogResponse
{
    public responseStatus: ResponseStatus;
    public emails: MailLog[];
    public resultCount: number;
}

export class HelloResponse
{
    public result: string;
}

export class TestImagusResponse
{
    public responseStatus: ResponseStatus;
    public authenticationUrl: string;
    public json: string;
    public results: Object[];
}

export class GetLastOmimCSVResponse
{
    public responseStatus: ResponseStatus;
    public fileName: string;
    public fileSizeBytes: number;
    public dateUploaded: string;
}

export class UploadOmimCSVResponse
{
    public responseStatus: ResponseStatus;
}

export class OmimPhenotypeResponse
{
    public responseStatus: ResponseStatus;
    public phenotype: OmimPhenotype;
}

export class OmimGenotypeResponse
{
    public responseStatus: ResponseStatus;
    public genotype: OmimGenotype;
}

export class OmimSelectionResponse
{
    public responseStatus: ResponseStatus;
    public genotype: OmimGenotype;
    public phenotype: OmimPhenotype;
}

export class OmimAutocompleteResponse
{
    public responseStatus: ResponseStatus;
    public phenotypes: OmimPhenotype[];
    public genotypes: OmimGenotype[];
    public searchType: OmimSearchType;
    public total: number;
}

export class OmimValidationResponse
{
    public responseStatus: ResponseStatus;
    public isValid: boolean;
}

export class SendEnrolChildLaterResponse
{
    public responseStatus: ResponseStatus;
}

export class PatientSingleByIdResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class GetPatientsByParentResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];
}

export class GetPatientsByDoctorResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];
}

export class GetPatientsByExpertResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];
}

export class GetPatientsByAssociatedDoctorResponse
{
    public responseStatus: ResponseStatus;
    public patients: Patient[];
}

export class GetPatientByStudyNumberResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class UploadFaceMatchResponse
{
    public responseStatus: ResponseStatus;
}

export class SaveAssociatedDoctorResponse
{
    public responseStatus: ResponseStatus;
    public doctor: Person;
}

export class SavePatientResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class SaveReferenceResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class SaveShowForMatchingResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class SaveInadequateQualityResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class GetPatientMatchBatchesResponse
{
    public responseStatus: ResponseStatus;
    public batches: MatchBatch[];
}

export class GetPatientMatchBatchResponse
{
    public responseStatus: ResponseStatus;
    public batch: MatchBatch;
}

export class GetPatientMatchResultsResponse
{
    public responseStatus: ResponseStatus;
    public results: MatchResult[];
}

export class QueueMatchBatchResponse
{
    public responseStatus: ResponseStatus;
    public batch: MatchBatch;
    public patient: Patient;
}

export class GetPatientCommentsResponse
{
    public responseStatus: ResponseStatus;
    public comments: Comment[];
}

export class SetStatusResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class SetDiagnosisResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class GetAuthenticatedPersonResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;
    public impersonation: boolean;
}

export class RegisterAsParentResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;
    public responseMessage: string;
}

export class RegisterAsDoctorResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;
    public responseMessage: string;
}

export class LoadPeopleResponse
{
    public responseStatus: ResponseStatus;
    public people: Person[];
    public resultCount: number;
}

export class GetPersonByIdResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;
}

export class DeletePersonByIdResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;
}

export class SaveProfileResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;
}

export class GetPeopleByRoleResponse
{
    public responseStatus: ResponseStatus;
    public people: Person[];
}

export class VerifyEmailByGuidResponse
{
    public responseStatus: ResponseStatus;
}

export class ResendEmailVerificationResponse
{
    public responseStatus: ResponseStatus;
}

export class GetPersonTokenByIdResponse
{
    public responseStatus: ResponseStatus;
    public personToken: PersonToken;
}

export class PostUpdatePasswordResponse
{
    public responseStatus: ResponseStatus;
}

export class LoadPublicDoctorsResponse
{
    public responseStatus: ResponseStatus;
    public doctors: Person[];
}

export class GetReferencePatientByStudyNumberResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class SaveReferenceShowForMatchingResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class SaveReferenceInadequateQualityResponse
{
    public responseStatus: ResponseStatus;
    public patient: Patient;
}

export class GenerateTemplateResponse
{
    public responseStatus: ResponseStatus;
    public message: Mail;
}

export class GetAllTemplatesResponse
{
    public responseStatus: ResponseStatus;
    public templates: Template[];
}

export class GetCustomisableTemplatesResponse
{
    public responseStatus: ResponseStatus;
    public templates: Template[];
}

export class SendGeneratedTemplateResponse
{
    public responseStatus: ResponseStatus;
}

export class UploadFileResponse
{
    public responseStatus: ResponseStatus;
    public guid: string;
    public fileName: string;
}

// @DataContract
export class RegisterResponse
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=8)
    public meta: { [index:string]: string; };
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=9)
    public meta: { [index:string]: string; };
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public responseStatus: ResponseStatus;
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public responseStatus: ResponseStatus;
}

// @Route("/analysis/algorithm", "GET")
export class GetAnalysisAlgorithms implements IReturn<GetAnalysisAlgorithmsResponse>, IGet
{
    public createResponse() { return new GetAnalysisAlgorithmsResponse(); }
    public getTypeName() { return 'GetAnalysisAlgorithms'; }
}

// @Route("/patients/job/{StudyNumber}", "GET")
// @Route("/patients/job/{StudyNumber}/{JobId}", "GET")
export class GetPatientJob implements IReturn<GetPatientJobResponse>, IGet, IHasStudyNumber
{
    /**
    * The Study Number of the patient
    */
    // @ApiMember(DataType="string", Description="The Study Number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;

    /**
    * The ID of the Job
    */
    // @ApiMember(DataType="int", Description="The ID of the Job", Name="Job ID", ParameterType="path")
    public jobId: number;
    public createResponse() { return new GetPatientJobResponse(); }
    public getTypeName() { return 'GetPatientJob'; }
}

// @Route("/patients/jobs/{StudyNumber}", "GET")
export class GetPatientJobs implements IReturn<GetPatientJobsResponse>, IGet, IHasStudyNumber
{
    /**
    * The Study Number of the patient
    */
    // @ApiMember(DataType="string", Description="The Study Number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;
    public createResponse() { return new GetPatientJobsResponse(); }
    public getTypeName() { return 'GetPatientJobs'; }
}

// @Route("/analysis/job", "POST")
export class QueueJob implements IReturn<QueueJobResponse>, IGet, IHasPatientId
{
    /**
    * The ID of the Patient
    */
    // @ApiMember(DataType="int", Description="The ID of the Patient", IsRequired=true, Name="Patient ID", ParameterType="body")
    public patientId: number;

    /**
    * The ID of the Algorithm
    */
    // @ApiMember(DataType="int", Description="The ID of the Algorithm", IsRequired=true, Name="Algorithm ID", ParameterType="body")
    public algorithmId: number;

    /**
    * Photographs to submit
    */
    // @ApiMember(DataType="int", Description="Photographs to submit", IsRequired=true, Name="Photographs", ParameterType="body")
    public matchIds: number[];
    public createResponse() { return new QueueJobResponse(); }
    public getTypeName() { return 'QueueJob'; }
}

// @Route("/patients/job-reference/{StudyNumber}/{JobId}/{Limit}/{Skip}", "GET")
export class GetPatientJobReferences implements IReturn<GetPatientJobReferencesResponse>, IGet, IHasStudyNumber
{
    /**
    * The Study Number of the patient
    */
    // @ApiMember(DataType="string", Description="The Study Number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;

    /**
    * The ID of the Job
    */
    // @ApiMember(DataType="int", Description="The ID of the Job", IsRequired=true, Name="Job ID", ParameterType="path")
    public jobId: number;

    /**
    * Number of results to return
    */
    // @ApiMember(DataType="int", Description="Number of results to return", IsRequired=true, Name="Limit Results", ParameterType="path")
    public limit: number;

    /**
    * Number of results to skip
    */
    // @ApiMember(DataType="int", Description="Number of results to skip", IsRequired=true, Name="Skip Results", ParameterType="path")
    public skip: number;
    public createResponse() { return new GetPatientJobReferencesResponse(); }
    public getTypeName() { return 'GetPatientJobReferences'; }
}

// @Route("/dna-variants/{Transcript}/{Variant}", "GET")
// @Route("/dna-variants", "GET")
export class FindDnaVariant implements IReturn<FindDnaVariantResponse>, IGet
{
    /**
    * Gene or Transcript to find
    */
    // @ApiMember(DataType="string", Description="Gene or Transcript to find", IsRequired=true, Name="Gene or Transcript", ParameterType="path")
    public transcript: string;

    /**
    * Number of records to return
    */
    // @ApiMember(DataType="number", Description="Number of records to return", Name="Count", ParameterType="path")
    public count: number;
    public createResponse() { return new FindDnaVariantResponse(); }
    public getTypeName() { return 'FindDnaVariant'; }
}

// @Route("/face/{Id}", "GET")
export class GetFaceImage implements IReturn<Blob>, IGet, IHasFileId
{
    /**
    * The ID of the file
    */
    // @ApiMember(DataType="integer", Description="The ID of the file", Format="int32", IsRequired=true, Name="File ID", ParameterType="path")
    public id: number;
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFaceImage'; }
}

// @Route("/face/search/{Id}", "GET")
export class GetSearchFaceImage implements IReturn<Blob>, IGet, IHasFileId
{
    /**
    * The ID of the file
    */
    // @ApiMember(DataType="integer", Description="The ID of the file", Format="int32", IsRequired=true, Name="File ID", ParameterType="path")
    public id: number;
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetSearchFaceImage'; }
}

// @Route("/image/{Id}", "GET")
export class GetImage implements IReturn<Blob>, IGet, IHasFileId
{
    /**
    * The ID of the file
    */
    // @ApiMember(DataType="integer", Description="The ID of the file", Format="int32", IsRequired=true, Name="File ID", ParameterType="path")
    public id: number;

    /**
    * The width at which to return the file.
    */
    // @ApiMember(DataType="integer", Description="The width at which to return the file.", Format="int32", IsRequired=true, Name="Width", ParameterType="query")
    public width: number;

    /**
    * The height at which to return the file
    */
    // @ApiMember(DataType="integer", Description="The height at which to return the file", Format="int32", IsRequired=true, Name="Height", ParameterType="query")
    public height: number;
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetImage'; }
}

// @Route("/file/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet, IHasFileId
{
    /**
    * The ID of the file
    */
    // @ApiMember(DataType="integer", Description="The ID of the file", Format="int32", IsRequired=true, Name="File ID", ParameterType="path")
    public id: number;
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFile'; }
}

// @Route("/hpo-terms/{SearchTerm}", "GET")
export class FindHpoTerms implements IReturn<FindHpoTermResponse>, IGet
{
    /**
    * Search Term for filtering Hpo Terms
    */
    // @ApiMember(DataType="string", Description="Search Term for filtering Hpo Terms", IsRequired=true, Name="SearchTerm", ParameterType="path")
    public searchTerm: string;
    public createResponse() { return new FindHpoTermResponse(); }
    public getTypeName() { return 'FindHpoTerms'; }
}

// @Route("/map-hpo-terms", "GET")
export class MapHpoTerms implements IReturn<FindHpoTermResponse>, IGet
{
    public createResponse() { return new FindHpoTermResponse(); }
    public getTypeName() { return 'MapHpoTerms'; }
}

// @Route("/invitation/by-guid/{Guid}", "GET")
export class GetInvitationByGuid implements IReturn<GetInvitationByGuidResponse>, IGet
{
    /**
    * The GUID of the invitation.
    */
    // @ApiMember(DataType="string", Description="The GUID of the invitation.", IsRequired=true, Name="GUID", ParameterType="path")
    public guid: string;
    public createResponse() { return new GetInvitationByGuidResponse(); }
    public getTypeName() { return 'GetInvitationByGuid'; }
}

// @Route("/invitation/acccept/by-guid", "POST")
export class AcceptInvitationByGuid implements IReturn<AcceptInvitationByGuidResponse>, IPost
{
    /**
    * The GUID of the invitation.
    */
    // @ApiMember(DataType="string", Description="The GUID of the invitation.", IsRequired=true, Name="GUID", ParameterType="body")
    public guid: string;
    public createResponse() { return new AcceptInvitationByGuidResponse(); }
    public getTypeName() { return 'AcceptInvitationByGuid'; }
}

// @Route("/invitation/reject/by-guid", "POST")
export class RejectInvitationByGuid implements IReturn<RejectInvitationByGuidResponse>, IPost
{
    /**
    * The GUID of the invitation.
    */
    // @ApiMember(DataType="string", Description="The GUID of the invitation.", IsRequired=true, Name="GUID", ParameterType="body")
    public guid: string;

    /**
    * The reason for rejecting the invitation.
    */
    // @ApiMember(DataType="string", Description="The reason for rejecting the invitation.", IsRequired=true, Name="Reason", ParameterType="body")
    public reason: string;
    public createResponse() { return new RejectInvitationByGuidResponse(); }
    public getTypeName() { return 'RejectInvitationByGuid'; }
}

// @Route("/invitation/cancel/by-guid", "POST")
export class CancelInvitationByGuid implements IReturn<CancelInvitationByGuidResponse>, IPost
{
    /**
    * The GUID of the invitation.
    */
    // @ApiMember(DataType="string", Description="The GUID of the invitation.", IsRequired=true, Name="GUID", ParameterType="body")
    public guid: string;

    /**
    * The reason for rejecting the invitation.
    */
    // @ApiMember(DataType="string", Description="The reason for rejecting the invitation.", IsRequired=true, Name="Reason", ParameterType="body")
    public reason: string;
    public createResponse() { return new CancelInvitationByGuidResponse(); }
    public getTypeName() { return 'CancelInvitationByGuid'; }
}

// @Route("/invitation/resend/by-guid", "POST")
export class ResendInvitationByGuid implements IReturn<ResendInvitationByGuidResponse>, IPost
{
    /**
    * The GUID of the invitation.
    */
    // @ApiMember(DataType="string", Description="The GUID of the invitation.", IsRequired=true, Name="GUID", ParameterType="body")
    public guid: string;
    public createResponse() { return new ResendInvitationByGuidResponse(); }
    public getTypeName() { return 'ResendInvitationByGuid'; }
}

// @Route("/invitation/send/parent", "POST")
export class SendParentInvite implements IReturn<SendParentInviteResponse>, IPost
{
    /**
    * The details of the invitation to be sent.
    */
    // @ApiMember(DataType="string", Description="The details of the invitation to be sent.", IsRequired=true, Name="Invitation", ParameterType="body")
    public invitation: Invitation;

    /**
    * The Person id for the doctor chosen by the patient
    */
    // @ApiMember(DataType="int", Description="The Person id for the doctor chosen by the patient", IsRequired=true, Name="DoctorId", ParameterType="body")
    public doctorId: string;

    /**
    * Token for checking when executing user isn't existing Facemant user
    */
    // @ApiMember(DataType="string", Description="Token for checking when executing user isn\'t existing Facemant user", IsRequired=true, Name="Token", ParameterType="body")
    public token: string;
    public createResponse() { return new SendParentInviteResponse(); }
    public getTypeName() { return 'SendParentInvite'; }
}

// @Route("/invitations/by-current-parent", "GET")
export class GetInvitationsByParent implements IReturn<GetInvitationsByParentResponse>, IGet
{
    public createResponse() { return new GetInvitationsByParentResponse(); }
    public getTypeName() { return 'GetInvitationsByParent'; }
}

// @Route("/invitations/by-current-doctor", "GET")
export class GetInvitationsByDoctor implements IReturn<GetInvitationsByDoctorResponse>, IGet
{
    public createResponse() { return new GetInvitationsByDoctorResponse(); }
    public getTypeName() { return 'GetInvitationsByDoctor'; }
}

// @Route("/invitation/update/{guid}", "GET")
export class UpdateInvitationUserByGuid implements IReturn<UpdateInvitationUserByGuidResponse>, IGet
{
    public createResponse() { return new UpdateInvitationUserByGuidResponse(); }
    public getTypeName() { return 'UpdateInvitationUserByGuid'; }
}

// @Route("/invitation/acccept-parent/by-guid", "POST")
export class AcceptInvitationFromDoctorByGuid implements IReturn<AcceptInvitationFromDoctorByGuidResponse>, IPost
{
    /**
    * The GUID of the invitation.
    */
    // @ApiMember(DataType="string", Description="The GUID of the invitation.", IsRequired=true, Name="GUID", ParameterType="body")
    public guid: string;
    public createResponse() { return new AcceptInvitationFromDoctorByGuidResponse(); }
    public getTypeName() { return 'AcceptInvitationFromDoctorByGuid'; }
}

// @Route("/lookup/{Lookups}", "GET")
export class GetLookups implements IReturn<GetLookupsResponse>, IGet
{
    /**
    * Name of the lookups to load
    */
    // @ApiMember(DataType="array", Description="Name of the lookups to load", IsRequired=true, Name="Lookups", ParameterType="path")
    public lookups: LookupType[];
    public createResponse() { return new GetLookupsResponse(); }
    public getTypeName() { return 'GetLookups'; }
}

// @Route("/Email", "GET")
export class GetEmails implements IReturn<GetEmailsResponse>, IGet
{
    public createResponse() { return new GetEmailsResponse(); }
    public getTypeName() { return 'GetEmails'; }
}

// @Route("/emaillog")
// @Route("/emaillog/{Number}")
// @Route("/emaillog/{Number}/{Page}")
export class LoadEmailLog implements IReturn<LoadEmailLogResponse>, IPost, IGet
{
    public number: number;
    public page: number;
    public tableFilter: TableFilter[];
    public tableSort: TableSort[];
    public createResponse() { return new LoadEmailLogResponse(); }
    public getTypeName() { return 'LoadEmailLog'; }
}

// @Route("/hello")
// @Route("/hello/{Name}")
export class Hello implements IReturn<HelloResponse>
{
    public name: string;
    public createResponse() { return new HelloResponse(); }
    public getTypeName() { return 'Hello'; }
}

// @Route("/imagus/test", "GET")
export class TestImagus implements IReturn<TestImagusResponse>, IGet
{
    public createResponse() { return new TestImagusResponse(); }
    public getTypeName() { return 'TestImagus'; }
}

export class GetLastOmimCSV implements IReturn<GetLastOmimCSVResponse>, IPost
{
    public createResponse() { return new GetLastOmimCSVResponse(); }
    public getTypeName() { return 'GetLastOmimCSV'; }
}

export class UploadOmimCSV implements IReturn<UploadOmimCSVResponse>, IPost
{
    /**
    * 
    */
    // @ApiMember(DataType="string", Description="", IsRequired=true, Name="File name", ParameterType="body")
    public fileName: string;

    /**
    * File as a base64 encoded string
    */
    // @ApiMember(DataType="string", Description="File as a base64 encoded string", IsRequired=true, Name="File upload", ParameterType="body")
    public file: string;
    public createResponse() { return new UploadOmimCSVResponse(); }
    public getTypeName() { return 'UploadOmimCSV'; }
}

// @Route("/omim/phenotype", "GET")
export class OmimGetPhenotype implements IReturn<OmimPhenotypeResponse>, IGet
{
    public omimPhenotypeName: string;
    public omimPhenotypeMimNumber: string;
    public createResponse() { return new OmimPhenotypeResponse(); }
    public getTypeName() { return 'OmimGetPhenotype'; }
}

// @Route("/omim/genotype", "GET")
export class OmimGetGenotype implements IReturn<OmimGenotypeResponse>, IGet
{
    public omimPhenotypeName: string;
    public omimPhenotypeMimNumber: string;
    public omimGenotypeName: string;
    public omimGenotypeMimNumber: string;
    public createResponse() { return new OmimGenotypeResponse(); }
    public getTypeName() { return 'OmimGetGenotype'; }
}

// @Route("/omim/genotype", "GET")
export class OmimGetSelection implements IReturn<OmimSelectionResponse>, IGet
{
    public omimPhenotypeName: string;
    public omimPhenotypeMimNumber: string;
    public omimGenotypeName: string;
    public omimGenotypeMimNumber: string;
    public createResponse() { return new OmimSelectionResponse(); }
    public getTypeName() { return 'OmimGetSelection'; }
}

// @Route("/omim/autocomplete", "GET")
export class OmimAutocomplete implements IReturn<OmimAutocompleteResponse>, IGet
{
    /**
    * The text input from the user when doing autocomplete search
    */
    // @ApiMember(DataType="string", Description="The text input from the user when doing autocomplete search", IsRequired=true, Name="User input", ParameterType="path")
    public userInput: string;

    /**
    * The type of search to process
    */
    // @ApiMember(DataType="enum", Description="The type of search to process", IsRequired=true, Name="Search type", ParameterType="path")
    public searchType: OmimSearchType;

    /**
    * If set then return the associated phenotypes
    */
    // @ApiMember(DataType="int", Description="If set then return the associated phenotypes", Name="Genotype id", ParameterType="path")
    public genotypeId: number;

    /**
    * If set then return the associated genotypes
    */
    // @ApiMember(DataType="int", Description="If set then return the associated genotypes", Name="Phenotype id", ParameterType="path")
    public phenotypeId: number;

    /**
    * Used for paganation
    */
    // @ApiMember(DataType="int", Description="Used for paganation", Name="Skip", ParameterType="path")
    public skip: number;

    /**
    * Used for paganation
    */
    // @ApiMember(DataType="int", Description="Used for paganation", Name="Take", ParameterType="path")
    public take: number;
    public createResponse() { return new OmimAutocompleteResponse(); }
    public getTypeName() { return 'OmimAutocomplete'; }
}

export class OmimValidation implements IReturn<OmimValidationResponse>, IGet
{
    /**
    * 
    */
    // @ApiMember(DataType="string", Description="", IsRequired=true, Name="Omim Phenotype Name", ParameterType="path")
    public omimPhenotypeName: string;

    /**
    * 
    */
    // @ApiMember(DataType="string", Description="", IsRequired=true, Name="Omim Gene Name", ParameterType="path")
    public omimGeneName: string;

    /**
    * 
    */
    // @ApiMember(DataType="string", Description="", IsRequired=true, Name="Omim Phenotype MIM Number", ParameterType="path")
    public omimPhenotypeMIMNumber: string;

    /**
    * 
    */
    // @ApiMember(DataType="string", Description="", IsRequired=true, Name="Omim Gene MIM Number", ParameterType="path")
    public omimGeneMIMNumber: string;
    public createResponse() { return new OmimValidationResponse(); }
    public getTypeName() { return 'OmimValidation'; }
}

// @Route("/parent/enrol-child-later", "GET")
export class SendEnrolChildLater implements IReturn<SendEnrolChildLaterResponse>, IPost
{
    public createResponse() { return new SendEnrolChildLaterResponse(); }
    public getTypeName() { return 'SendEnrolChildLater'; }
}

// @Route("/patient/{PatientId}", "GET")
export class PatientSingleById implements IReturn<PatientSingleByIdResponse>, IGet
{
    /**
    * The patient id of the patient to retrieve
    */
    // @ApiMember(DataType="int", Description="The patient id of the patient to retrieve", IsRequired=true, Name="PatientId", ParameterType="path")
    public patientId: number;
    public createResponse() { return new PatientSingleByIdResponse(); }
    public getTypeName() { return 'PatientSingleById'; }
}

// @Route("/patients/by-current-parent", "GET")
export class GetPatientsByParent implements IReturn<GetPatientsByParentResponse>, IGet
{
    public createResponse() { return new GetPatientsByParentResponse(); }
    public getTypeName() { return 'GetPatientsByParent'; }
}

// @Route("/patients/by-current-doctor", "GET")
export class GetPatientsByDoctor implements IReturn<GetPatientsByDoctorResponse>, IGet
{
    public createResponse() { return new GetPatientsByDoctorResponse(); }
    public getTypeName() { return 'GetPatientsByDoctor'; }
}

// @Route("/patients/by-current-expert", "GET")
export class GetPatientsByExpert implements IReturn<GetPatientsByExpertResponse>, IGet
{
    public createResponse() { return new GetPatientsByExpertResponse(); }
    public getTypeName() { return 'GetPatientsByExpert'; }
}

// @Route("/patients/export-by-expert", "GET")
export class ExportPatientsByExpert implements IReturn<GetPatientsByExpertResponse>, IGet
{
    public expertTableFilter: string;
    public createResponse() { return new GetPatientsByExpertResponse(); }
    public getTypeName() { return 'ExportPatientsByExpert'; }
}

// @Route("/patients/by-current-associated-doctor", "GET")
export class GetPatientsByAssociatedDoctor implements IReturn<GetPatientsByAssociatedDoctorResponse>, IGet
{
    public createResponse() { return new GetPatientsByAssociatedDoctorResponse(); }
    public getTypeName() { return 'GetPatientsByAssociatedDoctor'; }
}

// @Route("/patients/by-study-number/{StudyNumber}", "GET")
export class GetPatientByStudyNumber implements IReturn<GetPatientByStudyNumberResponse>, IGet, IHasStudyNumber
{
    /**
    * The study number of the patient
    */
    // @ApiMember(DataType="string", Description="The study number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;
    public createResponse() { return new GetPatientByStudyNumberResponse(); }
    public getTypeName() { return 'GetPatientByStudyNumber'; }
}

// @Route("/patients", "GET")
export class UploadFaceMatch implements IReturn<UploadFaceMatchResponse>, IGet
{
    public createResponse() { return new UploadFaceMatchResponse(); }
    public getTypeName() { return 'UploadFaceMatch'; }
}

// @Route("/patients/save-associated-doctor", "Post")
export class SaveAssociatedDoctor implements IReturn<SaveAssociatedDoctorResponse>, IPost
{
    /**
    * Id of the doctor to add
    */
    // @ApiMember(DataType="int", Description="Id of the doctor to add", IsRequired=true, Name="DoctorId", ParameterType="body")
    public doctorId: number;

    /**
    * The id of the patient to add the doctor to
    */
    // @ApiMember(DataType="int", Description="The id of the patient to add the doctor to", IsRequired=true, Name="PatientPersonId", ParameterType="body")
    public patientPersonId: number;
    public createResponse() { return new SaveAssociatedDoctorResponse(); }
    public getTypeName() { return 'SaveAssociatedDoctor'; }
}

export class RemoveAssociatedDoctor implements IReturn<SaveAssociatedDoctorResponse>, IPost
{
    /**
    * Id of the doctor to add
    */
    // @ApiMember(DataType="int", Description="Id of the doctor to add", IsRequired=true, Name="DoctorId", ParameterType="body")
    public doctorId: number;

    /**
    * The id of the patient to add the doctor to
    */
    // @ApiMember(DataType="int", Description="The id of the patient to add the doctor to", IsRequired=true, Name="PatientPersonId", ParameterType="body")
    public patientPersonId: number;
    public createResponse() { return new SaveAssociatedDoctorResponse(); }
    public getTypeName() { return 'RemoveAssociatedDoctor'; }
}

// @Route("/patients/save", "Post")
export class SavePatient implements IReturn<SavePatientResponse>, IPost, IHasPatient
{
    /**
    * The patient to be saved
    */
    // @ApiMember(DataType="object", Description="The patient to be saved", IsRequired=true, Name="Patient", ParameterType="body")
    public patient: Patient;

    /**
    * A comment for the patient
    */
    // @ApiMember(DataType="string", Description="A comment for the patient", Name="Comment", ParameterType="body")
    public comment: string;
    public createResponse() { return new SavePatientResponse(); }
    public getTypeName() { return 'SavePatient'; }
}

// @Route("/patients/save-reference", "Post")
export class SaveReference implements IReturn<SaveReferenceResponse>, IPost
{
    /**
    * Patient identifier
    */
    // @ApiMember(DataType="int", Description="Patient identifier", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * Photo identifier
    */
    // @ApiMember(DataType="int", Description="Photo identifier", IsRequired=true, Name="PhotoId", ParameterType="body")
    public photoId: number;

    /**
    * Whether this is a reference photo or not
    */
    // @ApiMember(DataType="boolean", Description="Whether this is a reference photo or not", IsRequired=true, Name="Reference", ParameterType="body")
    public reference: boolean;
    public createResponse() { return new SaveReferenceResponse(); }
    public getTypeName() { return 'SaveReference'; }
}

// @Route("/patients/save-reference-photo", "Post")
export class SaveShowForMatching implements IReturn<SaveShowForMatchingResponse>, IPost
{
    /**
    * Patient identifier
    */
    // @ApiMember(DataType="int", Description="Patient identifier", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * Photo identifier
    */
    // @ApiMember(DataType="int", Description="Photo identifier", IsRequired=true, Name="PhotoId", ParameterType="body")
    public photoId: number;

    /**
    * Whether this is a reference photo or not
    */
    // @ApiMember(DataType="boolean", Description="Whether this is a reference photo or not", IsRequired=true, Name="Reference", ParameterType="body")
    public showForMatching: boolean;
    public createResponse() { return new SaveShowForMatchingResponse(); }
    public getTypeName() { return 'SaveShowForMatching'; }
}

// @Route("/patients/save-reference-photo", "Post")
export class SaveInadequateQuality implements IReturn<SaveInadequateQualityResponse>, IPost
{
    /**
    * Patient identifier
    */
    // @ApiMember(DataType="int", Description="Patient identifier", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * Photo identifier
    */
    // @ApiMember(DataType="int", Description="Photo identifier", IsRequired=true, Name="PhotoId", ParameterType="body")
    public photoId: number;

    /**
    * Whether this is a reference photo or not
    */
    // @ApiMember(DataType="boolean", Description="Whether this is a reference photo or not", IsRequired=true, Name="InadequateQuality", ParameterType="body")
    public inadequateQuality: boolean;
    public createResponse() { return new SaveInadequateQualityResponse(); }
    public getTypeName() { return 'SaveInadequateQuality'; }
}

// @Route("/patients/match-batches/{StudyNumber}", "GET")
export class GetPatientMatchBatches implements IReturn<GetPatientMatchBatchesResponse>, IGet, IHasStudyNumber
{
    /**
    * The Study Number of the patient
    */
    // @ApiMember(DataType="string", Description="The Study Number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;
    public createResponse() { return new GetPatientMatchBatchesResponse(); }
    public getTypeName() { return 'GetPatientMatchBatches'; }
}

// @Route("/patients/match-batch/{StudyNumber}", "GET")
// @Route("/patients/match-batch/{StudyNumber}/{MatchBatchId}", "GET")
export class GetPatientMatchBatch implements IReturn<GetPatientMatchBatchResponse>, IGet, IHasStudyNumber
{
    /**
    * The Study Number of the patient
    */
    // @ApiMember(DataType="string", Description="The Study Number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;

    /**
    * The ID of the Match Batch
    */
    // @ApiMember(DataType="int", Description="The ID of the Match Batch", Name="Match Batch ID", ParameterType="path")
    public matchBatchId: number;
    public createResponse() { return new GetPatientMatchBatchResponse(); }
    public getTypeName() { return 'GetPatientMatchBatch'; }
}

// @Route("/patients/match-results/{StudyNumber}/{MatchId}/{Limit}/{Skip}", "GET")
export class GetPatientMatchResults implements IReturn<GetPatientMatchResultsResponse>, IGet, IHasStudyNumber
{
    /**
    * The Study Number of the patient
    */
    // @ApiMember(DataType="string", Description="The Study Number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;

    /**
    * The ID of the Match
    */
    // @ApiMember(DataType="int", Description="The ID of the Match", IsRequired=true, Name="Match ID", ParameterType="path")
    public matchId: number;

    /**
    * Number of results to return
    */
    // @ApiMember(DataType="int", Description="Number of results to return", IsRequired=true, Name="Limit Results", ParameterType="path")
    public limit: number;

    /**
    * Number of results to skip
    */
    // @ApiMember(DataType="int", Description="Number of results to skip", IsRequired=true, Name="Skip Results", ParameterType="path")
    public skip: number;
    public createResponse() { return new GetPatientMatchResultsResponse(); }
    public getTypeName() { return 'GetPatientMatchResults'; }
}

// @Route("/patients/match-results/{PatientId}", "POST")
export class QueueMatchBatch implements IReturn<QueueMatchBatchResponse>, IGet, IHasPatientId
{
    /**
    * The ID of the Patient
    */
    // @ApiMember(DataType="int", Description="The ID of the Patient", IsRequired=true, Name="Patient ID", ParameterType="path")
    public patientId: number;

    /**
    * Photographs to submit
    */
    // @ApiMember(DataType="int", Description="Photographs to submit", IsRequired=true, Name="Photographs", ParameterType="path")
    public photographs: number[];

    public queueAlgorithm: QueueAlgorithm;
    public referenceChecked: boolean;
    public confirmedDiagnosisChecked: boolean;
    public undiagnosedChecked: boolean;
    public createResponse() { return new QueueMatchBatchResponse(); }
    public getTypeName() { return 'QueueMatchBatch'; }
}

// @Route("/patients/comments/{StudyNumber}", "GET")
export class GetPatientComments implements IReturn<GetPatientCommentsResponse>, IGet, IHasStudyNumber
{
    /**
    * The Study Number of the patient
    */
    // @ApiMember(DataType="string", Description="The Study Number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;
    public createResponse() { return new GetPatientCommentsResponse(); }
    public getTypeName() { return 'GetPatientComments'; }
}

// @Route("/patients/set-status", "Post")
export class SetStatus implements IReturn<SetStatusResponse>, IPost
{
    /**
    * The id of the patient to save
    */
    // @ApiMember(DataType="int", Description="The id of the patient to save", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * The status to set the patient to
    */
    // @ApiMember(DataType="object", Description="The status to set the patient to", IsRequired=true, Name="PatientStatus", ParameterType="body")
    public status: PatientStatus;

    /**
    * A comment to go along with the status
    */
    // @ApiMember(DataType="string", Description="A comment to go along with the status", Name="Comment", ParameterType="body")
    public comment: string;
    public createResponse() { return new SetStatusResponse(); }
    public getTypeName() { return 'SetStatus'; }
}

// @Route("/patients/set-diagnosis", "Post")
export class SetDiagnosis implements IReturn<SetDiagnosisResponse>, IPost
{
    /**
    * The id of the patient to save
    */
    // @ApiMember(DataType="int", Description="The id of the patient to save", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * The diagnosis to set the patient to
    */
    // @ApiMember(DataType="int", Description="The diagnosis to set the patient to", IsRequired=true, Name="FaceMatchDiagnosisId", ParameterType="body")
    public faceMatchDiagnosisId: number;

    /**
    * A diagnosis note
    */
    // @ApiMember(DataType="string", Description="A diagnosis note", Name="FaceMatchDiagnosisNotes", ParameterType="body")
    public faceMatchDiagnosisNotes: string;
    public createResponse() { return new SetDiagnosisResponse(); }
    public getTypeName() { return 'SetDiagnosis'; }
}

// @Route("/person/authenticated", "GET")
export class GetAuthenticatedPerson implements IReturn<GetAuthenticatedPersonResponse>, IGet
{
    public createResponse() { return new GetAuthenticatedPersonResponse(); }
    public getTypeName() { return 'GetAuthenticatedPerson'; }
}

// @Route("/person/register/parent", "POST")
export class RegisterAsParent implements IReturn<RegisterAsParentResponse>, IPost
{
    /**
    * The person registering as a parent
    */
    // @ApiMember(DataType="object", Description="The person registering as a parent", IsRequired=true, Name="Person", ParameterType="body")
    public person: Person;

    /**
    * The password entered by the user
    */
    // @ApiMember(DataType="string", Description="The password entered by the user", IsRequired=true, Name="Password", ParameterType="body")
    public password: string;

    /**
    * Whether or not the person is registering from an invitation
    */
    // @ApiMember(DataType="boolean", Description="Whether or not the person is registering from an invitation", IsRequired=true, Name="RegisterFromInvitation", ParameterType="body")
    public registerFromInvitation: boolean;

    /**
    * The guid of the invite
    */
    // @ApiMember(DataType="string", Description="The guid of the invite", Name="InvitationGuid", ParameterType="body")
    public invitationGuid: string;
    public createResponse() { return new RegisterAsParentResponse(); }
    public getTypeName() { return 'RegisterAsParent'; }
}

// @Route("/person/register/doctor", "POST")
export class RegisterAsDoctor implements IReturn<RegisterAsDoctorResponse>, IPost
{
    /**
    * The person registering as a doctor
    */
    // @ApiMember(DataType="object", Description="The person registering as a doctor", IsRequired=true, Name="Person", ParameterType="body")
    public person: Person;

    /**
    * The password entered by the user
    */
    // @ApiMember(DataType="string", Description="The password entered by the user", IsRequired=true, Name="Password", ParameterType="body")
    public password: string;

    /**
    * The guid of the invite
    */
    // @ApiMember(DataType="string", Description="The guid of the invite", Name="InvitationGuid", ParameterType="body")
    public invitationGuid: string;
    public createResponse() { return new RegisterAsDoctorResponse(); }
    public getTypeName() { return 'RegisterAsDoctor'; }
}

// @Route("/people")
// @Route("/people/{Number}")
// @Route("/people/{Number}/{Page}")
export class LoadPeople implements IReturn<LoadPeopleResponse>, IPost, IGet
{
    public number: number;
    public page: number;
    public tableFilter: TableFilter[];
    public tableSort: TableSort[];
    public createResponse() { return new LoadPeopleResponse(); }
    public getTypeName() { return 'LoadPeople'; }
}

// @Route("/export/patient")
export class ExportPatients implements IReturn<LoadPeopleResponse>, IGet
{
    public startDate: string;
    public endDate: string;
    public tableFilter: string;
    public createResponse() { return new LoadPeopleResponse(); }
    public getTypeName() { return 'ExportPatients'; }
}

// @Route("/export/people")
export class ExportPeople implements IReturn<LoadPeopleResponse>, IGet
{
    public startDate: string;
    public endDate: string;
    public tableFilter: string;
    public createResponse() { return new LoadPeopleResponse(); }
    public getTypeName() { return 'ExportPeople'; }
}

// @Route("/impersonate", "GET")
export class ImpersonatePerson implements IReturnVoid, IGet
{
    public personId: number;
    public continue: string;
    public createResponse() {}
    public getTypeName() { return 'ImpersonatePerson'; }
}

// @Route("/person/by-id/{Id}", "GET")
export class GetPersonById implements IReturn<GetPersonByIdResponse>, IGet
{
    /**
    * The id of the person
    */
    // @ApiMember(DataType="int", Description="The id of the person", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;
    public createResponse() { return new GetPersonByIdResponse(); }
    public getTypeName() { return 'GetPersonById'; }
}

// @Route("/person/delete-by-id/{Id}", "GET")
export class DeletePersonById implements IReturn<DeletePersonByIdResponse>, IGet
{
    /**
    * The id of the person
    */
    // @ApiMember(DataType="int", Description="The id of the person", IsRequired=true, Name="Id", ParameterType="path")
    public id: number;
    public createResponse() { return new DeletePersonByIdResponse(); }
    public getTypeName() { return 'DeletePersonById'; }
}

// @Route("/person/profile/save", "POST")
export class SaveProfile implements IReturn<SaveProfileResponse>, IPost
{
    /**
    * The person saving their profile
    */
    // @ApiMember(DataType="object", Description="The person saving their profile", IsRequired=true, Name="Person", ParameterType="body")
    public person: Person;
    public createResponse() { return new SaveProfileResponse(); }
    public getTypeName() { return 'SaveProfile'; }
}

// @Route("/people/role/{RoleId}", "GET")
export class GetPeopleByRole implements IReturn<GetPeopleByRoleResponse>, IGet
{
    /**
    * The role id of the people to retrieve
    */
    // @ApiMember(DataType="int", Description="The role id of the people to retrieve", IsRequired=true, Name="RoleId", ParameterType="path")
    public roleId: number;
    public createResponse() { return new GetPeopleByRoleResponse(); }
    public getTypeName() { return 'GetPeopleByRole'; }
}

// @Route("/email/verification/by-guid", "POST")
export class VerifyEmailByGuid implements IReturn<VerifyEmailByGuidResponse>, IPost
{
    /**
    * The GUID of the email verification.
    */
    // @ApiMember(DataType="string", Description="The GUID of the email verification.", IsRequired=true, Name="GUID", ParameterType="body")
    public guid: string;
    public createResponse() { return new VerifyEmailByGuidResponse(); }
    public getTypeName() { return 'VerifyEmailByGuid'; }
}

// @Route("/person/email-verification", "POST")
export class ResendEmailVerification implements IReturn<ResendEmailVerificationResponse>, IPost
{
    public createResponse() { return new ResendEmailVerificationResponse(); }
    public getTypeName() { return 'ResendEmailVerification'; }
}

// @Route("/auth/{provider}/reset", "POST")
export class ResetPasswordEmail implements IReturn<ResponseStatus>, IPost
{
    /**
    * The email address of the account whose password is being reset
    */
    // @ApiMember(DataType="string", Description="The email address of the account whose password is being reset", IsRequired=true, Name="Email", ParameterType="body")
    public email: string;
    public createResponse() { return new ResponseStatus(); }
    public getTypeName() { return 'ResetPasswordEmail'; }
}

// @Route("/personToken/by-id/{Id}", "GET")
export class GetPersonTokenById implements IReturn<GetPersonTokenByIdResponse>, IGet
{
    /**
    * The Id of the user requesting the token
    */
    // @ApiMember(DataType="int", Description="The Id of the user requesting the token", IsRequired=true, Name="personId", ParameterType="path")
    public personId: number;
    public createResponse() { return new GetPersonTokenByIdResponse(); }
    public getTypeName() { return 'GetPersonTokenById'; }
}

// @Route("/auth/update", "POST")
export class PostUpdatePassword implements IReturn<PostUpdatePasswordResponse>, IPost
{
    /**
    * The Id of the user requesting the token
    */
    // @ApiMember(DataType="int", Description="The Id of the user requesting the token", IsRequired=true, Name="personId", ParameterType="body")
    public personId: number;

    /**
    * The new password of the user requesting the reset
    */
    // @ApiMember(DataType="string", Description="The new password of the user requesting the reset", IsRequired=true, Name="password", ParameterType="body")
    public password: string;

    /**
    * The verification token for the user requesting the reset
    */
    // @ApiMember(DataType="string", Description="The verification token for the user requesting the reset", IsRequired=true, Name="token", ParameterType="body")
    public token: string;
    public createResponse() { return new PostUpdatePasswordResponse(); }
    public getTypeName() { return 'PostUpdatePassword'; }
}

// @Route("/people/{email}", "GET")
export class CheckAccoutnExists implements IReturn<ResponseStatus>, IGet
{
    /**
    * The email to be checked
    */
    // @ApiMember(DataType="string", Description="The email to be checked", IsRequired=true, Name="email", ParameterType="path")
    public email: string;
    public createResponse() { return new ResponseStatus(); }
    public getTypeName() { return 'CheckAccoutnExists'; }
}

// @Route("/bulk-reset", "GET")
export class BulkResetPasswordEmail implements IReturn<ResponseStatus>, IPost
{
    public createResponse() { return new ResponseStatus(); }
    public getTypeName() { return 'BulkResetPasswordEmail'; }
}

// @Route("/doctors", "GET")
export class LoadPublicDoctors implements IReturn<LoadPublicDoctorsResponse>, IGet
{
    public createResponse() { return new LoadPublicDoctorsResponse(); }
    public getTypeName() { return 'LoadPublicDoctors'; }
}

export class GetReferencePatientByStudyNumber implements IReturn<GetReferencePatientByStudyNumberResponse>, IGet, IHasStudyNumber
{
    /**
    * The study number of the patient
    */
    // @ApiMember(DataType="string", Description="The study number of the patient", IsRequired=true, Name="Study Number", ParameterType="path")
    public studyNumber: string;
    public createResponse() { return new GetReferencePatientByStudyNumberResponse(); }
    public getTypeName() { return 'GetReferencePatientByStudyNumber'; }
}

// @Route("/reference-patients/save-reference-photo", "Post")
export class SaveReferenceShowForMatching implements IReturn<SaveReferenceShowForMatchingResponse>, IPost
{
    /**
    * Patient identifier
    */
    // @ApiMember(DataType="int", Description="Patient identifier", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * Photo identifier
    */
    // @ApiMember(DataType="int", Description="Photo identifier", IsRequired=true, Name="PhotoId", ParameterType="body")
    public photoId: number;

    /**
    * Whether this is a reference photo or not
    */
    // @ApiMember(DataType="boolean", Description="Whether this is a reference photo or not", IsRequired=true, Name="Reference", ParameterType="body")
    public showForMatching: boolean;
    public createResponse() { return new SaveReferenceShowForMatchingResponse(); }
    public getTypeName() { return 'SaveReferenceShowForMatching'; }
}

// @Route("/reference-patients/save-reference-photo", "Post")
export class SaveReferenceInadequateQuality implements IReturn<SaveReferenceInadequateQualityResponse>, IPost
{
    /**
    * Patient identifier
    */
    // @ApiMember(DataType="int", Description="Patient identifier", IsRequired=true, Name="PatientId", ParameterType="body")
    public patientId: number;

    /**
    * Photo identifier
    */
    // @ApiMember(DataType="int", Description="Photo identifier", IsRequired=true, Name="PhotoId", ParameterType="body")
    public photoId: number;

    /**
    * Whether this is a reference photo or not
    */
    // @ApiMember(DataType="boolean", Description="Whether this is a reference photo or not", IsRequired=true, Name="InadequateQuality", ParameterType="body")
    public inadequateQuality: boolean;
    public createResponse() { return new SaveReferenceInadequateQualityResponse(); }
    public getTypeName() { return 'SaveReferenceInadequateQuality'; }
}

// @Route("/generate-template", "POST")
export class GenerateTemplate implements IReturn<GenerateTemplateResponse>, IPost
{
    /**
    * The Id of the template.
    */
    // @ApiMember(DataType="int", Description="The Id of the template.", IsRequired=true, Name="Id", ParameterType="body")
    public id: TemplateType;

    /**
    * The TemplateData for the template.
    */
    // @ApiMember(DataType="dictionary", Description="The TemplateData for the template.", IsRequired=true, Name="TemplateData", ParameterType="body")
    public templateData: { [index:string]: Object; };
    public createResponse() { return new GenerateTemplateResponse(); }
    public getTypeName() { return 'GenerateTemplate'; }
}

// @Route("/get-all-templates", "GET")
export class GetAllTemplates implements IReturn<GetAllTemplatesResponse>, IGet
{
    public createResponse() { return new GetAllTemplatesResponse(); }
    public getTypeName() { return 'GetAllTemplates'; }
}

// @Route("/get-customisable-templates", "GET")
export class GetCustomisableTemplates implements IReturn<GetCustomisableTemplatesResponse>, IGet
{
    public createResponse() { return new GetCustomisableTemplatesResponse(); }
    public getTypeName() { return 'GetCustomisableTemplates'; }
}

// @Route("/send-generated-template", "POST")
export class SendGeneratedTemplate implements IReturn<SendGeneratedTemplateResponse>, IPost
{
    public mail: Mail;
    public patientId: number;
    public personId: number;
    public createResponse() { return new SendGeneratedTemplateResponse(); }
    public getTypeName() { return 'SendGeneratedTemplate'; }
}

// @Route("/temporary-file/upload", "POST")
export class UploadFile implements IReturn<UploadFileResponse>
{
    public json: boolean;
    public createResponse() { return new UploadFileResponse(); }
    public getTypeName() { return 'UploadFile'; }
}

// @Route("/temporary-file/image/{Guid}", "GET")
export class GetTemporaryImage implements IReturn<Blob>, IGet
{
    /**
    * The Guid of the file
    */
    // @ApiMember(DataType="integer", Description="The Guid of the file", Format="int32", IsRequired=true, Name="File Guid", ParameterType="path")
    public guid: string;

    /**
    * The width at which to return the file.
    */
    // @ApiMember(DataType="integer", Description="The width at which to return the file.", Format="int32", IsRequired=true, Name="Width", ParameterType="query")
    public width: number;

    /**
    * The height at which to return the file
    */
    // @ApiMember(DataType="integer", Description="The height at which to return the file", Format="int32", IsRequired=true, Name="Height", ParameterType="query")
    public height: number;
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetTemporaryImage'; }
}

// @Route("/temporary-file/file/{Guid}", "GET")
export class GetTemporaryFile implements IReturn<Blob>, IGet
{
    /**
    * The Guid of the file
    */
    // @ApiMember(DataType="integer", Description="The Guid of the file", Format="int32", IsRequired=true, Name="File Guid", ParameterType="path")
    public guid: string;
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetTemporaryFile'; }
}

// @Route("/register")
// @DataContract
export class Register implements IReturn<RegisterResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public firstName: string;

    // @DataMember(Order=3)
    public lastName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public email: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public autoLogin: boolean;

    // @DataMember(Order=8)
    public continue: string;
    public createResponse() { return new RegisterResponse(); }
    public getTypeName() { return 'Register'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe: boolean;

    // @DataMember(Order=8)
    public continue: string;

    // @DataMember(Order=9)
    public nonce: string;

    // @DataMember(Order=10)
    public uri: string;

    // @DataMember(Order=11)
    public response: string;

    // @DataMember(Order=12)
    public qop: string;

    // @DataMember(Order=13)
    public nc: string;

    // @DataMember(Order=14)
    public cnonce: string;

    // @DataMember(Order=15)
    public useTokenCookie: boolean;

    // @DataMember(Order=16)
    public accessToken: string;

    // @DataMember(Order=17)
    public accessTokenSecret: string;

    // @DataMember(Order=18)
    public meta: { [index:string]: string; };
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;
    public createResponse() { return new GetApiKeysResponse(); }
    public getTypeName() { return 'GetApiKeys'; }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;
    public createResponse() { return new RegenerateApiKeysResponse(); }
    public getTypeName() { return 'RegenerateApiKeys'; }
}

